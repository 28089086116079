/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useMemo } from 'react';

import { QueryClient, keepPreviousData, useQuery } from '@tanstack/react-query';
import { isZoover } from '@vakantiesnl/components/src/utils/brandChecks';
import { formatFallbackTemplate, shouldShowFallbackTemplate } from '@vakantiesnl/components/src/utils/contentful';
import { getGeoEntitiesFromSlugs } from '@vakantiesnl/components/src/utils/geo';
import {
	BrandedSearchTemplateArgs,
	FallbackArgs,
	buildContentfulSlug,
	fetchBrandedSearchTemplate,
	getBrandedSearchTemplateQueryKey,
	getCachedSearchTemplateFromContentful,
	postFormatBrandedTemplate,
} from '@vakantiesnl/services/src/contentfulTemplates';
import { useMicroCopyContext } from '@vakantiesnl/services/src/context/microCopyContext';
import { useGetGeo, GeoState } from '@vakantiesnl/services/src/hooks/queries';
import { useFilterStore, FilterState } from '@vakantiesnl/services/src/stores/filtersStore';
import { getMinutesInMs } from '@vakantiesnl/services/src/util';
import { replaceUndefinedByNull } from '@vakantiesnl/services/src/util/replaceUndefinedByNull';
import { MicroCopy, SearchTemplate, ZooverSearchTemplate } from '@vakantiesnl/types';
import { useRouter } from 'next/router';

type BrandedSearchData = {
	brandedSearchTemplate: ZooverSearchTemplate | null;
};

export function useBrandedSearchTemplate(): BrandedSearchData {
	const geo = useGetGeo();
	const filters = useFilterStore((s) => s.filters);
	const router = useRouter();
	const microCopy = useMicroCopyContext();
	const contentfulSlug = buildContentfulSlug(filters);

	const args: BrandedSearchTemplateArgs = {
		contentfulSlug,
		page: filters.page,
		locale: router.locale,
		fallbackOpts: {
			shouldFallback: () => shouldShowFallbackTemplate(filters),
			getFallbackTemplate: getFallbackTemplate(filters, geo, microCopy),
		},
	};

	const query = useQuery({
		queryKey: getBrandedSearchTemplateQueryKey(args),
		staleTime: getMinutesInMs(15),
		queryFn: () => fetchBrandedSearchTemplate(args).then(replaceUndefinedByNull),
		placeholderData: keepPreviousData,
	});

	const brandedSearchTemplate = useMemo(() => {
		return postFormatBrandedTemplate(
			filters.page,
			isZoover ? filters.transport === 'OWN' : undefined,
			contentfulSlug,
			query.data,
		) as ZooverSearchTemplate;
	}, [filters.page, filters.transport, contentfulSlug, query.data]);

	return {
		brandedSearchTemplate,
	};
}

export async function getBrandedSearchTemplate(
	queryClient: QueryClient,
	args: BrandedSearchTemplateArgs,
): Promise<ZooverSearchTemplate | SearchTemplate | null> {
	const template = await queryClient.fetchQuery({
		queryKey: getBrandedSearchTemplateQueryKey(args),
		queryFn: () => fetchBrandedSearchTemplate(args).then(replaceUndefinedByNull),
	});

	return postFormatBrandedTemplate(
		args.page,
		isZoover ? args.ownTransport : undefined,
		args.contentfulSlug,
		template,
	);
}

export function getFallbackTemplate(filters: FilterState, geo: GeoState, microCopy: MicroCopy) {
	return async ({ contentfulSlug, locale, fallback }: FallbackArgs) => {
		const { country, region, city } = getGeoEntitiesFromSlugs(filters, geo);

		/** Use geoEntity in order for branded search pages */
		const geoEntity = city[0] || region[0] || country[0];

		/** Use a SEO fallback template */
		if (geoEntity && contentfulSlug) {
			const brandedSearchTemplate = await getCachedSearchTemplateFromContentful(fallback, locale);

			return (
				brandedSearchTemplate &&
				formatFallbackTemplate(brandedSearchTemplate, geoEntity, contentfulSlug, filters, microCopy)
			);
		}
	};
}
