import {
	createCountryDestinationRequest,
	mapDestinationCountryResponse,
	createRegionDestinationRequest,
	mapDestinationRegionsResponse,
	createCityDestinationRequest,
	mapDestinationCityResponse,
	mapDestinationCityResponses,
} from '@vakantiesnl/services/src/geo';
import { errorHandler, parseErrorToReport } from '@vakantiesnl/services/src/util/errorHandling';
import { fetchVakNL } from '@vakantiesnl/services/src/util/vaknl-fetch';
import { Geo } from '@vakantiesnl/types/src';

//** The following functions are only the actual fetching part, not to be used directly */
export async function fetchCountries(): Promise<Geo.MappedDestinationView[]> {
	try {
		const rawCountries: Geo.RawCountryResponse = await fetchVakNL({
			input: createCountryDestinationRequest(),
		}).then((response) => response.json());

		return mapDestinationCountryResponse(rawCountries);
	} catch (e) {
		errorHandler.report(parseErrorToReport(e, 'Error fetching countries'));

		throw e;
	}
}

export async function fetchRegions(countrySlugs: string[]): Promise<Geo.MappedDestinationViewBySlug> {
	try {
		const response = await Promise.all(
			countrySlugs.map<Promise<Geo.RawRegionsResponse>>((slug) => {
				return fetchVakNL({
					input: createRegionDestinationRequest(slug),
				}).then((response) => response.json());
			}),
		);

		return mapDestinationRegionsResponse(response);
	} catch (e) {
		errorHandler.report(parseErrorToReport(e, 'Error fetching regions'));

		throw new Error('Cannot fetch regions');
	}
}

export async function fetchCities(regionsSlugs: string[]): Promise<Geo.MappedDestinationViewBySlug> {
	try {
		const mappedCityResponses = await Promise.all(
			regionsSlugs.map<Promise<Geo.MappedDestinationViewBySlug>>(async (regionSlug) => {
				return fetchVakNL({
					input: createCityDestinationRequest(regionSlug),
				})
					.then((response) => response.json())
					.then((result) => mapDestinationCityResponse(regionSlug, result));
			}),
		);

		return mapDestinationCityResponses(mappedCityResponses);
	} catch (e) {
		errorHandler.report(parseErrorToReport(e, 'Error fetching cities'));

		throw new Error('Cannot fetch cities');
	}
}
