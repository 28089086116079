import {
	mapBookingAdditionalCosts,
	mapBookingCostsExtras,
	mapFundsExtras,
} from '@vakantiesnl/services/src/dynamic-booking';
import { Search } from '@vakantiesnl/types';
import {
	AdditionalCost,
	ProductInfo,
	Property,
	RawAdditionalCost,
	RawProductInfo,
	RawProperty,
} from '@vakantiesnl/types/src/search';
import dayjs from 'dayjs';

export const genAccoOnlyOfferId = ({
	departureDate,
	durationNights,
	productInfo,
}: Omit<Search.AccommodationOnlyOffer, 'id'>): string =>
	Object.values({
		departureDate: dayjs(departureDate).format('YYYY-MM-DD'),
		durationNights,
		productId: productInfo?.productId,
	}).join('|');

export const mapAccoOnlyOffer = (rawOffer: Search.RawAccoOnlyOffer): Search.AccommodationOnlyOffer => {
	const offer: Omit<Search.AccommodationOnlyOffer, 'id'> = {
		brand: rawOffer.brand,
		departureDate: dayjs(rawOffer.departure_date, 'YYYY-MM-DD').toDate().toString(),
		departureDateYYYYMMDD: rawOffer.departure_date,
		discountAmount: rawOffer?.discount_amount ? Number(rawOffer?.discount_amount) : null,
		expirationDate: rawOffer?.expiration_date ?? null,
		durationDays: rawOffer.duration_days,
		durationNights: rawOffer.duration_nights,
		extras: {
			funds: mapFundsExtras(rawOffer.extras.funds),
			surcharges: mapBookingCostsExtras(rawOffer.extras.surcharges),
			additionalCosts: mapBookingAdditionalCosts(rawOffer.extras.additional_costs),
		},
		priceTotal: rawOffer.price_total,
		pricePackage: rawOffer.price_package,
		pricePerAdult: rawOffer.price_per_adult,
		pricePerChild: rawOffer.price_per_child,
		pricePerBaby: rawOffer.price_per_baby,
		originalPricePerPerson: rawOffer.original_price_per_person ?? null,
		productCode: rawOffer.product_code,
		tourOperatorId: rawOffer.tour_operator_id,
		productInfo: rawOffer.product_info ? mapProductInfo(rawOffer.product_info) : null,
	};

	return { ...offer, id: genAccoOnlyOfferId(offer) };
};

export const mapProductInfo = (productInfo: RawProductInfo): ProductInfo => ({
	country: productInfo.country,
	region: productInfo.region,
	city: productInfo.city,
	name: productInfo.name,
	nameTranslations: productInfo.name_translations,
	shortDescriptions: productInfo.short_descriptions,
	longDescriptions: productInfo.long_descriptions,
	extraDescriptions: productInfo.extra_descriptions,
	touroperator: productInfo.touroperator,
	touroperatorCode: productInfo.touroperator_code,
	productId: productInfo.product_id,
	typeCode: productInfo.type_code,
	longitude: productInfo.longitude,
	latitude: productInfo.latitude,
	minOccupancy: productInfo.min_occupancy,
	maxOccupancy: productInfo.max_occupancy,
	images: productInfo.images,
	productType: productInfo.product_type,
	additionalCosts: productInfo.additional_costs.map(mapAdditionalCosts),
	park: productInfo.park,
	properties: productInfo.properties.map(mapProperties),
});

const mapProperties = (rawProperty: RawProperty): Property => ({
	code: rawProperty.code,
	units: rawProperty.units,
	unitTag: rawProperty.unit_tag,
	descriptions: rawProperty.descriptions,
	quantity: rawProperty.quantity,
});

const mapAdditionalCosts = (rawAdditionalCosts: RawAdditionalCost): AdditionalCost => ({
	productTag: rawAdditionalCosts.product_tag,
	names: rawAdditionalCosts.names,
	code: rawAdditionalCosts.code,
	descriptions: rawAdditionalCosts.descriptions,
	price: rawAdditionalCosts.price,
	units: rawAdditionalCosts.units,
	unitTag: rawAdditionalCosts.unit_tag,
	obligated: rawAdditionalCosts.obligated,
	payAtLocation: rawAdditionalCosts.pay_at_location,
	conditions: rawAdditionalCosts.conditions,
});

/**
 * AccommodationRequestBody
 * @example {"brand":"VAKNL","departure_date":"2022-12-12","durations":[3,4,5,6],"party":{"adults":2,"babies":0,"children":0},"post_offset":0,"pre_offset":0}
 */
export interface AccommodationRequestBody {
	/**
	 * Departure Date
	 * ISO 8601 date format.
	 */
	departure_date?: string | null;
	/**
	 * Pre Offset
	 * For flexible departure.
	 * @default 0
	 */
	pre_offset?: number;
	/**
	 * Post Offset
	 * For flexible departure.
	 * @default 0
	 */
	post_offset?: number;
	/** @default "VAKNL" */
	brand?: string;
	/** Durations */
	durations?: number[] | null;
	party: Search.PartyComposition;
}
