import { QueryClient } from '@tanstack/react-query';
import {
	getValidCitiesFromQuery,
	getValidCountriesFromQuery,
	getValidRegionsFromQuery,
} from '@vakantiesnl/services/src/util/queryHelpers';
import { Geo } from '@vakantiesnl/types/src';

import { fetchCountries, fetchRegions, fetchCities } from './geoQueryFns';
import { getCitiesQueryId, getCountriesQueryId, getRegionsQueryId } from './helpers';
import { GeoQueryParams, GeoState, GeoStateWithSelected } from './interfaces';

//** The following functions can be used to get the geoState with react-query in GSSP/GIP */
export async function getCountries(queryClient: QueryClient): Promise<GeoState['countries']> {
	return await queryClient.fetchQuery({
		queryKey: getCountriesQueryId(),
		queryFn: fetchCountries,
	});
}

export async function getRegions(queryClient: QueryClient, countrySlugs: string[]): Promise<GeoState['regions']> {
	return await queryClient.fetchQuery({
		queryKey: getRegionsQueryId(countrySlugs),
		queryFn: () => fetchRegions(countrySlugs),
	});
}

export async function getCities(queryClient: QueryClient, regionSlugs: string[]): Promise<GeoState['regions']> {
	return await queryClient.fetchQuery({
		queryKey: getCitiesQueryId(regionSlugs),
		queryFn: () => fetchCities(regionSlugs),
	});
}

export async function getGeo(queryClient: QueryClient, geoQueryParams: GeoQueryParams): Promise<GeoStateWithSelected> {
	const countries = await getCountries(queryClient);

	const selectedCountries = getValidCountriesFromQuery(geoQueryParams, countries);
	const selectedCountySlugs = selectedCountries.map((c) => c.slug);

	let selectedRegions: Geo.MappedDestinationView[] = [];
	let selectedCities: Geo.MappedDestinationView[] = [];

	let regions: Geo.MappedDestinationViewBySlug | undefined;
	let cities: Geo.MappedDestinationViewBySlug | undefined;

	if (selectedCountries.length) {
		regions = await getRegions(queryClient, selectedCountySlugs);

		selectedRegions = getValidRegionsFromQuery(geoQueryParams, regions);
		const selectedRegionSlugs = selectedRegions.map((r) => r.slug);

		if (selectedRegions.length && selectedCountries.length === 1) {
			cities = await getCities(queryClient, selectedRegionSlugs);
			selectedCities = getValidCitiesFromQuery(geoQueryParams, cities);
		}
	}

	return {
		geoState: {
			countries,
			regions: regions ?? {},
			cities: cities ?? {},
		},
		selectedGeo: {
			countries: selectedCountries,
			regions: selectedRegions,
			cities: selectedCities,
		},
	};
}
