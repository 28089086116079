import { ParsedUrlQuery } from 'querystring';

import { QueryClient } from '@tanstack/react-query';

import { GeoState, getGeo } from '.';
import { FilterState } from '../../stores/filtersStore';
import { getFilterState } from '../../stores/filtersStore/getFilterState';
import { getGeoParamsFromQuery } from '../../util/queryHelpers';

export type SearchPageState = {
	geoState: GeoState;
	filterState: FilterState;
};

export async function getSearchPageState(queryClient: QueryClient, query: ParsedUrlQuery): Promise<SearchPageState> {
	const geoQueryParams = getGeoParamsFromQuery(query);
	const { geoState, selectedGeo } = await getGeo(queryClient, geoQueryParams);
	const filterState = getFilterState(query, selectedGeo);

	return {
		geoState,
		filterState,
	};
}
