/* istanbul ignore file */
import { QueryClient } from '@tanstack/react-query';
import { BookingDocumentData } from '@vakantiesnl/services/src/user';
import { fetchHandler } from '@vakantiesnl/services/src/util/fetchHandler';

import { LOGGED_IN_QUERY_KEY } from './constants';

export async function fetchUserBookingDocumentData(
	bookingDocumentId: number,
): Promise<BookingDocumentData['fileObject']> {
	return fetchHandler({
		fetchFn: () =>
			fetch('/api/user/bookingDocument', { headers: { bookingDocumentId: bookingDocumentId.toString() } }),
		errorMessage: `Something went wrong during fetching the booking document for bookingDocumentId ${bookingDocumentId}`,
	});
}

export async function fetchUserBookingDocument(
	queryClient: QueryClient,
	bookingDocumentId: number,
): Promise<BookingDocumentData['fileObject'] | undefined> {
	try {
		return await queryClient.fetchQuery({
			queryKey: [LOGGED_IN_QUERY_KEY, 'user-booking-document-data', bookingDocumentId],
			queryFn: () => fetchUserBookingDocumentData(bookingDocumentId),
		});
	} catch {
		// Don't throw an error if this crashes since it enhances the page and it's not the main content of the page
		return undefined;
	}
}
