import { useEffect, useState } from 'react';

import { RichTextElement, RichTextElementMap } from '@vakantiesnl/types';
import { Asset, Entry, EntryCollection } from 'contentful';
import { useRouter } from 'next/router';

import { getAsset, getEntry, getEntryCollection } from '../../contentful';
import { mapRichTextElements } from '../../contentful/mapper';

export type EntryCollectionReturnType<T> = {
	data: EntryCollection<T> | undefined;
};

export type EntryReturnType<T> = {
	data: Entry<T> | undefined;
};

const useEntryCollection = <T extends Record<string, unknown>>(
	contentType: string,
	query?: Record<string, unknown> | undefined,
): EntryCollectionReturnType<T> => {
	const [data, setData] = useState<EntryCollection<T>>();
	const { locale } = useRouter();

	useEffect(() => {
		(async function (): Promise<void> {
			// eslint-disable-next-line camelcase, @typescript-eslint/naming-convention
			getEntryCollection<T, Record<string, unknown>>(contentType, query, locale).then((entries) => {
				setData(entries);
			});
		})();
	}, [contentType, query, locale]); // Note that query updates trigger new API calls. You probably want to define query params in a constant outside your component, or use useMemo().
	return { data };
};

const useEntry = <T extends Record<string, unknown>>(id: string, contentType: string): EntryReturnType<T> => {
	const [data, setData] = useState<Entry<T>>();

	useEffect(() => {
		(async function (): Promise<void> {
			// eslint-disable-next-line camelcase, @typescript-eslint/naming-convention
			getEntry<T>(id, contentType).then((entry) => {
				setData(entry);
			});
		})();
	}, [contentType, id]);
	return { data };
};

// When using useAsset and passing in an id that doesn't exist,
// the type returned doesn't have a `fields` property. Adjust the contentful type
// so that it is more correct.
export interface CorrectAsset extends Omit<Asset, 'fields'> {
	fields?: Asset['fields'];
}

const useAsset = (id?: string): CorrectAsset | undefined => {
	const [data, setData] = useState<Asset | undefined>();
	useEffect(() => {
		(async function (): Promise<void> {
			if (id) {
				const asset = await getAsset(id);
				setData(asset);
				return;
			}
			setData(undefined);
		})();
	}, [id]);

	return data;
};

const useRichText = (query: Record<string, string[]>): RichTextElementMap | undefined => {
	const richTextElementsData = useEntryCollection<RichTextElement>('elementRichText', query).data;
	return richTextElementsData ? mapRichTextElements(richTextElementsData) : undefined;
};

export { useEntry, useEntryCollection, useRichText, useAsset };
export * from './useBrandedSearchTemplate';
