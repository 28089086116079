import { useEffect } from 'react';

export const replaceState = (key: string, state: Record<string, unknown>): void => {
	history?.replaceState(
		{
			...history.state,
			options: {
				...(history.state.options || {}),
				[key]: state,
			},
		},
		'',
	);
};

/**
 * Keeps track of the state and replaces the state using history.replaceState. Put's it in the history.state.options because this is the default state structure in the NextJS framework
 * @param key
 * @param state
 */
export const useTrackAndReplaceState = (key: string, state: Record<string, unknown>): void => {
	useEffect(() => {
		replaceState(key, state);
	}, [key, state]);
};

export const pushState = (key: string, state: Record<string, unknown>): void => {
	history?.pushState(
		{
			...history.state,
			options: {
				...(history.state.options || {}),
				[key]: state,
			},
		},
		'',
	);
};

export const useTrackAndPushState = (key: string, state: Record<string, unknown>): void => {
	useEffect(() => {
		pushState(key, state);
	}, [key, state]);
};

export const useOnPopStateEffect = (onPopState: (event: PopStateEvent) => void): void => {
	useEffect(() => {
		window.onpopstate = onPopState;
		return (): void => {
			window.onpopstate = null;
		};
	}, [onPopState]);
};

export const getHistoryState = <T>(key: string): T | undefined => {
	if (typeof history !== 'undefined' && history.state.options && history?.state?.options[key]) {
		return history?.state?.options[key] as T;
	}
	return;
};
